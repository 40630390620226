import React from "react";
import { Outlet } from "react-router-dom";

export default function Auth() {
  const divStyle = {
    backgroundImage: `url('/bg-img.png')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };
  return (
    <>

      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div className="absolute top-0 w-full h-full bg-no-repeat bg-full" style={divStyle} >
          </div>
          <Outlet />

        </section>
      </main>
    </>
  );
}
