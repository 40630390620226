import { splitApi } from "./spilitApi";

export const allUsers = splitApi.injectEndpoints({
  endpoints: (build) => ({
    getUserData: build.query({
      query: (role) => {
        let url = "/users";

        if (role === "seekers") {
          url = "/users?role=seekers";
        } else if (role === "employers") {
          url = "/users?role=employers";
        }

        return {
          url,
          method: "get",
        };
      },
      invalidatesTags: ["Users"],
    }),
  }),
});

export const { useGetUserDataQuery } = allUsers;
