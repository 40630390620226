import { splitApi } from "./spilitApi";

export const courseApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    getCourseData: build.query({
      query: (data) => ({
        url: `/course?page=${data}`,
        method: "GET",
      }),
      providesTags: ["Seekers"],
    }),
    createCourses: build.mutation({
      query: (data) => ({
        url: "/course",
        method: "POST",
        body: data,
      }),
      providesTags: ["Seekers"],
    }),
    deleteCourseData: build.mutation({
      query: (id) => ({
        url: `/course/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Seekers"],
    }),
    updateCourseData: build.mutation({
      query: ({ id, data }) => ({
        url: `/course/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Seekers"],
    }),
  }),
});

export const {
  useGetCourseDataQuery,
  useDeleteCourseDataMutation,
  useCreateCoursesMutation,
  useUpdateCourseDataMutation,
} = courseApi;
