import { splitApi } from "./spilitApi";

export const collegeApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    getColleges: build.query({
      query: () => ({
        url: "/college",
        method: "GET",
      }),
      providesTags: ["Seekers"],
    }),
  }),
});

export const { useGetCollegesQuery } = collegeApi;
