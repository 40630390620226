import { splitApi } from "./spilitApi";

export const manageReqApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    getManageReqData: build.query({
      query: (data) => ({
        url: `/users?role=employers&page=${data}&sortBy=createdAt:desc`, // Query parameters are added to the URL
        method: "GET", // GET request method
      }),
      providesTags: ["Seekers"],
    }),
    updateApplicationStatus: build.mutation({
      query: ({ id, data }) => ({
        url: `/users/update-application-status/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetManageReqDataQuery,
  useUpdateApplicationStatusMutation,
} = manageReqApi;
