import Sidebar from "../Sidebar/Sidebar";
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { college } from "../../redux/reducers/collegeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useGetCollegesQuery } from "../../redux/Apis/collegeApi";
import { useCreateCoursesMutation } from "../../redux/Apis/courseApi";
import { useGetCategoryQuery } from "../../redux/Apis/categoryApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .max(35, "Name is too long"),
  type: Yup.string().required("Job Preference is required"),
  description: Yup.string().required("description is required"),
  college: Yup.string().required("college is required"),
  price: Yup.string().required("price is required"),
  duration: Yup.string()
    .required("duration is required")
    .matches(
      /^([0-9]{2}):([0-5][0-9]):([0-5][0-9])$/,
      "Please enter a valid time in the format 'hh:mm:ss'"
    ),
  category: Yup.string().required("Category is required"),
});

const CourseForm = () => {
  const navigate = useNavigate();
  const collegeData = useSelector((state) => state.collegeSlice.college);
  const dispatch = useDispatch();

  const [category, setCategory] = useState([]);

  const {
    data: getCategory,
    isLoading,
    error: seekerError,
    isSuccess,
    isError: categoryError,
  } = useGetCategoryQuery();

  const {
    data: getData,
    isLoading: isCollegeLoading,
    error: collegeError,
    isSuccess: collegeFetched,
    isError,
    refetch: getCourseAgain,
  } = useGetCollegesQuery();

  const [
    createCourse,
    { isLoading: isCourseLoading, isSuccess: courseSuccess },
  ] = useCreateCoursesMutation();

  useEffect(() => {
    if (!isCollegeLoading && collegeFetched) {
      dispatch(college(getData.body.results));
    }
  }, [isCollegeLoading, collegeFetched, getData]);

  useEffect(() => {
    if (
      isSuccess &&
      getCategory &&
      getCategory.body &&
      getCategory.body.results
    ) {
      setCategory(getCategory.body.results);
    }
  }, [isLoading, getCategory]);

  const initialValues = {
    name: "",
    type: "",
    description: "",
    college: "",
    price: "",
    duration: "",
    category: "",
  };

  console.log(
    category,
    "<<<<<<<<<<<<<<<<<<<<<<<<<<category<<<<<<<<<<<<<<<<<<<"
  );

  if (courseSuccess) {
    toast.success("Course added successfully");
    navigate("/admin/courses");
  }

  const handleSubmit = async (values) => {
    console.log(values);
    try {
      const response = await createCourse(values);
      console.log("Job created successfully:", response.data);
    } catch (err) {
      console.error("Error creating job:", err);
    }
  };
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64">
        <div>
          <div className="flex w-2/3 align-center mt-6 mb-10 mx-auto">
            <h1 className="sector-heading text-black text-[20px] md:text-[36px] font-semibold">
              Create Course
            </h1>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="w-2/3 mx-auto">
                <div className="flex flex-wrap mb-6">
                  <div className="relative w-full md:w-1/2 px-3 my-6 md:mb-0">
                    <label
                      className="block tracking-wide text-[#1D1D1D] text-[20px] font-medium mb-2"
                      htmlFor="name"
                    >
                      Name
                    </label>
                    <Field
                      id="name"
                      name="name"
                      type="text"
                      className="bg-[#F6F8FF] pt-3 py-6 pr-10 md:py-6 border-none text-[#1D1D1D] font-1xl sm:text-sm rounded-lg block w-full p-2.5"
                      placeholder="Dk"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="relative w-full md:w-1/2 px-3 my-6 md:mb-0">
                    <label
                      className="block tracking-wide text-[#1D1D1D] text-[20px] font-medium mb-2"
                      htmlFor="type"
                    >
                      Type
                    </label>
                    <Field
                      id="type"
                      name="type"
                      type="text"
                      className="bg-[#F6F8FF] pt-3 py-6 pr-10 md:py-6 border-none text-[#1D1D1D] font-1xl sm:text-sm rounded-lg block w-full p-2.5"
                      placeholder="Permanent"
                    />
                    <ErrorMessage
                      name="type"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="relative w-full  px-3 my-6 md:mb-0">
                    <label
                      className="block tracking-wide text-[#1D1D1D] text-[20px] font-medium mb-2"
                      htmlFor="description"
                    >
                      Description
                    </label>
                    <Field
                      as="textarea"
                      name="description"
                      className="appearance-none block w-full bg-[#F6F8FF] border-none text-gray-700 border-gray-200 rounded py-3 px-4 leading-tight"
                      id="description"
                      placeholder="Description"
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="relative w-full px-3 my-6 md:mb-0">
                    <label
                      className="block tracking-wide text-[#1D1D1D] text-[20px] font-medium mb-2"
                      htmlFor="college"
                    >
                      College
                    </label>
                    <Field
                      as="select" // Use "as" prop to render select element
                      id="college"
                      name="college"
                      className="bg-[#F6F8FF] pt-3 py-6 pr-10 md:py-6 border-none text-[#1D1D1D] font-1xl sm:text-sm rounded-lg block w-full p-2.5"
                    >
                      <option value="">Select an option</option>
                      {getData &&
                        getData.body &&
                        getData.body.results.map((college, index) => (
                          <option value={college && college._id} key={index}>
                            {college && college.name}
                          </option>
                        ))}
                    </Field>
                    <ErrorMessage
                      name="college"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="relative w-full md:w-1/2 px-3 my-6 md:mb-0">
                    <label
                      className="block tracking-wide text-[#1D1D1D] text-[20px] font-medium mb-2"
                      htmlFor="price"
                    >
                      Price
                    </label>
                    <Field
                      id="price"
                      name="price"
                      type="string"
                      className="bg-[#F6F8FF] pt-3 py-6 pr-10 md:py-6 border-none text-[#1D1D1D] font-1xl sm:text-sm rounded-lg block w-full p-2.5"
                      placeholder="$100"
                    />
                    <ErrorMessage
                      name="price"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="relative w-full md:w-1/2 px-3 my-6 md:mb-0">
                    <label
                      className="block tracking-wide text-[#1D1D1D] text-[20px] font-medium mb-2"
                      htmlFor="duration"
                    >
                      Duration
                    </label>
                    <Field
                      id="duration"
                      name="duration"
                      type="text"
                      className="bg-[#F6F8FF] pt-3 py-6 pr-10 md:py-6 border-none text-[#1D1D1D] font-1xl sm:text-sm rounded-lg block w-full p-2.5"
                      placeholder="12:12:12"
                    />
                    <ErrorMessage
                      name="duration"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="relative w-full px-3 my-6 md:mb-0">
                    <label
                      className="block tracking-wide text-[#1D1D1D] text-[20px] font-medium mb-2"
                      htmlFor="category"
                    >
                      Category
                    </label>
                    <Field
                      as="select" // Use "as" prop to render select element
                      id="category"
                      name="category"
                      className="bg-[#F6F8FF] pt-3 py-6 pr-10 md:py-6 border-none text-[#1D1D1D] font-1xl sm:text-sm rounded-lg block w-full p-2.5"
                    >
                      <option value="">Select an option</option>
                      {category &&
                        category.map((item, index) => (
                          <option
                            value={item && item._id}
                            key={item && item.id}
                          >
                            {item && item.name}{" "}
                            {/* Display the category name */}
                          </option>
                        ))}
                    </Field>
                    <ErrorMessage
                      name="category"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full justify-center enquire-btn inline-flex items-center h-16 font-bold mt-5 bg-[#bdceee] text-black rounded-xl text-xl py-2 pl-[3.5rem] pr-[3.5rem]"
                >
                  Create Course
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CourseForm;
