import EnhancedTable from "../../components/Table/Table";
import React, { useEffect, useState } from "react";

import { jobListing } from "../../utils/jobListingHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetJobDataQuery,
  useDeleteJobsMutation,
  useUpdateJobsMutation,
} from "../../redux/Apis/jobsSeeker";
import { toast } from "react-toastify";
import { jobs, totalCount } from "../../redux/reducers/jobSlice";

const Jobs = () => {
  const jobsData = useSelector((state) => {
    return state.jobSlice.jobs;
  });
  const [page, setPage] = useState(1);
  const [data, setData] = useState(jobsData);
  console.log({ page });
  const jobCount = useSelector((state) => state.jobSlice.totalCount);

  const {
    data: getData,
    isLoading,
    error: seekerError,
    isSuccess: jobGoted,
    isError,

    refetch: getJobsAgain,
  } = useGetJobDataQuery(page);

  useEffect(() => {
    console.log({ data });
    if (!isLoading && jobGoted) {
      setData(getData.body.results);
    }
  }, [getData]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!isLoading && jobGoted) {
      dispatch(jobs(getData.body.results));
      dispatch(totalCount(getData.body.totalJobs));
    }
  }, [isLoading, jobGoted, getData]);

  const [
    updateJobData,
    { isLoading: isUpdating, isSuccess: updateSuccessfull },
  ] = useUpdateJobsMutation();

  const [deleteJobData, { isLoading: isDeleting }] = useDeleteJobsMutation();

  const handleUpdateJobData = async (id, updatedData) => {
    try {
      const updatedResponse = await updateJobData({ id, data: updatedData });
      if (updateSuccessfull) {
        toast.success("Job data updated successfully!");
        getJobsAgain();
      } else {
        console.log(updatedResponse, "Error updating");
        toast.error(updatedResponse.message);
      }
    } catch (error) {
      console.error("Error updating data:");
    }
    getJobsAgain();
  };

  const handleDeleteJobData = async (id) => {
    try {
      const deletedResponse = await deleteJobData(id);
      if (deletedResponse.data) {
        // Remove the deleted job from the data array
        const newData = data.filter((job) => job.id !== id);
        setData(newData);
        dispatch(jobs(newData)); // Update Redux store with the new job data
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const handlePageChange = (newPage) => {
    const nextPage = page + 1;
    setPage(nextPage);
    getJobsAgain(nextPage); // Fetch the next page of data
  };

  if (isLoading && !jobGoted && jobsData.length === 0) {
    return <h1>Loading...</h1>;
  } else {
    return (
      <>
        <EnhancedTable
          data={data}
          type="seeker"
          headCells={jobListing}
          totalData={jobCount}
          handleGetPage={handlePageChange}
          handleUpdateJobData={handleUpdateJobData}
          handleDeleteJobData={handleDeleteJobData}
        />
      </>
    );
  }
};

export default Jobs;
