import { splitApi } from "./spilitApi";

export const jobSeekerApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    getJobData: build.query({
      query: (data) => {
        console.log({ data });
        return {
          url: `/jobs?page=${data}`,
          method: "GET",
        };
      },
      providesTags: ["Seekers"],
    }),
    createJobs: build.mutation({
      query: (data) => ({
        url: "/jobs",
        method: "POST",
        body: data,
      }),
      providesTags: ["Seekers"],
    }),
    updateJobs: build.mutation({
      query: ({ id, data }) => ({
        url: `/jobs/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteJobs: build.mutation({
      query: (id) => ({
        url: `/jobs/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetJobDataQuery,
  useCreateJobsMutation,
  useDeleteJobsMutation,
  useUpdateJobsMutation,
} = jobSeekerApi;
