import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Sidebar from "../../../components/Sidebar/Sidebar";

// const validationSchema = Yup.object().shape({
//   name: Yup.string()
//     .required("Name is required")
//     .max(25, "Name is too long"),
//   profile: Yup.string()
//     .required("Profile is required")
//     .max(25, "Profile is too long"),
//   email: Yup.string()
//     .email("Invalid email")
//     .required("Email is required"),
//   phoneNumber: Yup.string().required("Phone number is required"),
//   isEmailVerified: Yup.string().required("Field cannot be empty"),
//   isSubscribed: Yup.string().required("Field cannot be empty"),
// });

const UpdateSeeker = ({ handleUpdateSeekerData }) => {
  //   const seekerInitialValues = {
  //     name: selectedUserData && selectedUserData.name,
  //     profile: selectedUserData && selectedUserData.profile,
  //     email: selectedUserData && selectedUserData.email,
  //     phoneNumber: selectedUserData && selectedUserData.phoneNumber,
  //     isEmailVerified:
  //       selectedUserData && selectedUserData.isEmailVerified ? "Yes" : "No",
  //     isSubscribed:
  //       selectedUserData && selectedUserData.isSubscribed ? "Yes" : "No",
  //   };
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64">heelo new</div>
    </>
    // <Formik
    //   initialValues={seekerInitialValues}
    //   validationSchema={validationSchema}
    //   onSubmit={(values) => {
    //     // Handle form submission here
    //     console.log(values, "new Values");
    //     console.log(
    //       selectedUserData.id,
    //       "<<<<<<<<<<<<<<<<<<<<,,id>>>>>>>>>>>>>>>>>>"
    //     );
    //     handleUpdateSeekerData(selectedUserData.id, {
    //       name: values.name,
    //       email: values.email,
    //       profile: values.profile,
    //       phoneNumber: values.phoneNumber,
    //     });
    //   }}
    // >
    //   {({ errors, touched }) => (
    //     <Form>
    //       <div className="flex justify-between mb-8 items-center">
    //         <label htmlFor="name">Name:</label>
    //         <Field type="text" id="name" name="name" className="rounded-lg" />
    //       </div>
    //       {errors.name && touched.name && (
    //         <div className="text-red-500 flex mb-5">{errors.name}</div>
    //       )}

    //       <div className="flex justify-between mb-8 items-center">
    //         <label htmlFor="profile">Profile:</label>
    //         <Field
    //           type="text"
    //           id="profile"
    //           name="profile"
    //           className="rounded-lg"
    //         />
    //       </div>
    //       {errors.profile && touched.profile && (
    //         <div className="text-red-500 flex mb-5">{errors.profile}</div>
    //       )}

    //       <div className="flex justify-between mb-8 items-center">
    //         <label htmlFor="email">Email:</label>
    //         <Field
    //           type="email"
    //           id="email"
    //           name="email"
    //           className="rounded-lg"
    //         />
    //       </div>
    //       {errors.email && touched.email && (
    //         <div className="text-red-500 flex mb-5">{errors.email}</div>
    //       )}

    //       <div className="flex justify-between mb-8 items-center">
    //         <label htmlFor="phoneNumber">Phone Number:</label>
    //         <Field
    //           type="text"
    //           id="phoneNumber"
    //           name="phoneNumber"
    //           className="rounded-lg"
    //         />
    //       </div>
    //       {errors.phoneNumber && touched.phoneNumber && (
    //         <div className="text-red-500 flex mb-5">{errors.phoneNumber}</div>
    //       )}

    //       <div className="flex justify-between mb-8 items-center">
    //         <label htmlFor="isEmailVerified">Is Email Verified:</label>
    //         <Field
    //           type="text"
    //           id="isEmailVerified"
    //           name="isEmailVerified"
    //           className="rounded-lg"
    //         />
    //       </div>
    //       {errors.isEmailVerified && touched.isEmailVerified && (
    //         <div className="text-red-500 flex mb-5">
    //           {errors.isEmailVerified}
    //         </div>
    //       )}

    //       <div className="flex justify-between mb-8 items-center">
    //         <label htmlFor="isSubscribed">Is Subscribed:</label>
    //         <Field
    //           type="text"
    //           id="isSubscribed"
    //           name="isSubscribed"
    //           className="rounded-lg"
    //         />
    //       </div>
    //       {errors.isSubscribed && touched.isSubscribed && (
    //         <div className="text-red-500 flex mb-5">{errors.isSubscribed}</div>
    //       )}
    //       <div className="flex justify-center">
    //         <button
    //           className="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded"
    //           type="submit"
    //         >
    //           Update
    //         </button>
    //       </div>
    //     </Form>
    //   )}
    // </Formik>
  );
};

export default UpdateSeeker;
