import { createSlice } from "@reduxjs/toolkit";
import { manageRequestInitialState } from "../reduxState";

export const manageRequestSlice = createSlice({
  name: "manageReq",
  initialState: manageRequestInitialState,
  reducers: {
    manageReq: (state, payload) => {
      console.log({ coursePayload: payload });
      state.manageReq = payload.payload;
    },
    totalCount: (state, payload) => {
      state.totalCount = payload.payload;
    },
  },
});

export const { manageReq, totalCount } = manageRequestSlice.actions;

export default manageRequestSlice.reducer;
