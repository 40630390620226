import { createSlice } from "@reduxjs/toolkit";
import { employerInitialSlice } from "../reduxState";

export const employerSlice = createSlice({
  name: "employer",
  initialState: employerInitialSlice,
  reducers: {
    employer: (state, payload) => {
      console.log({ paulodSeeker: payload });
      state.jobs = payload.payload;
    },
    totalCount: (state, payload) => {
      state.totalCount = payload.payload;
    },
  },
});

export const { employer, totalCount } = employerSlice.actions;

export default employerSlice.reducer;
