export const jobListing = [
  {
    id: "profile",
    numeric: false,
    disablePadding: true,
    label: "Profile",
  },
  {
    id: "employer",
    numeric: false,
    disablePadding: true,
    label: "Employer",
  },
  {
    id: "jobtype",
    numeric: false,
    disablePadding: true,
    label: "Job Type",
  },
  {
    id: "vacancies",
    numeric: false,
    disablePadding: true,
    label: "Vacancies",
  },
  {
    id: "salary",
    numeric: false,
    disablePadding: true,
    label: "Salary",
  },
  {
    id: "view",
    numeric: true,
    disablePadding: false,
    label: "View",
  },
];
