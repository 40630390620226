export const seekerHeaders = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "contact",
    numeric: false,
    disablePadding: false,
    label: "Contact",
  },
  {
    id: "profile",
    numeric: true,
    disablePadding: false,
    label: "Profile",
  },
  {
    id: "subscribed",
    numeric: true,
    disablePadding: false,
    label: "Subscribed",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];
