import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const splitApi = createApi({
  tagTypes: ["Jobs", "Seekers", "Auth", "Empoyeers", "Courses", "Revenue"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: async (headers) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  endpoints: () => ({}),
});
