import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga4";
import "../src/assets/styles/tailwind.css";

// layouts

import Admin from "./layouts/Admin.js";
import Auth from "./layouts/Auth.js";
import Dashboard from "./views/admin/Dashboard";
// views without layouts

import Login from "./views/auth/Login";
import Settings from "./views/admin/Settings";
import Tables from "./views/admin/Table";
import Profile from "./views/Profile";
import Seekers from "./views/admin/Seekers";
import Employers from "./views/admin/Employers";
import Jobs from "./views/admin/Jobs";
import Courses from "./views/admin/Courses";
import JobForm from "./components/Form/JobForm";
import ManageRequest from "./views/admin/ManageRequest";
import CourseForm from "./components/Form/CourseForm";
import UpdateSeeker from "./components/Form/UpdateSeeker/UpdateSeeker";
import Revenue from "./views/admin/Revenue";

ReactGA.initialize("G-FRDGFRC8QF");

const router = createBrowserRouter([
  {
    path: "/",
    element: <Admin />,

    children: [
      {
        path: "admin/dashboard",
        element: <Dashboard />,
      },
      {
        path: "admin/settings",
        element: <Settings />,
      },
      {
        path: "admin/tables",
        element: <Tables />,
      },
      {
        path: "admin/managerequest",
        element: <ManageRequest />,
      },
      {
        path: "admin/seekers",
        element: <Seekers />,
      },
      {
        path: "admin/employers",
        element: <Employers />,
      },
      {
        path: "admin/revenue",
        element: <Revenue />,
      },
      {
        path: "admin/jobs",
        element: <Jobs />,
        children: [
          {
            path: "admin/jobs/createJobs",
            element: <JobForm />,
          },
        ],
      },
      {
        path: "admin/courses",
        element: <Courses />,
      },
    ],
  },

  {
    path: "/auth",
    element: <Auth />,

    children: [
      {
        path: "login",
        element: <Login />,
      },
    ],
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/createJobs",
    element: <JobForm />,
  },

  {
    path: "/create-course",
    element: <CourseForm />,
  },
  {
    path: "/update-seeker",
    element: <UpdateSeeker />,
  },
]);

ReactGA.send({ hitType: "pageview", page: "/admin/seekers" });

ReactGA.send({
  hitType: "event",
  eventCategory: "User",
  eventAction: "Clicked Button",
});

const App = () => (
  <Provider store={store}>
    <RouterProvider router={router} />
    <ToastContainer />
  </Provider>
);

ReactDOM.render(<App />, document.getElementById("root"));
