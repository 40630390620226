import * as React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, FormControlLabel, Switch } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { Link } from "react-router-dom";
import { Modal } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import JobModal from "../../components/modal/JobModal";
import CourseModal from "../../components/modal/CourseModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #ECEDF2",
  borderRadius: "5%",
  boxShadow: 24,
  p: 4,
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .max(25, "Name is too long"),
  profile: Yup.string()
    .required("Profile is required")
    .max(25, "Profile is too long"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  phoneNumber: Yup.string().required("Phone number is required"),
  isEmailVerified: Yup.string().required("Field cannot be empty"),
  isSubscribed: Yup.string().required("Field cannot be empty"),
});

const EmpValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .max(25, "Name is too long"),
  fullName: Yup.string()
    .required("Full Name is required")
    .max(25, "Profile is too long"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  phoneNumber: Yup.string().required("Phone number is required"),
  isEmailVerified: Yup.string().required("Field cannot be empty"),
  isSubscribed: Yup.string().required("Field cannot be empty"),
});

function createData(data, type) {
  if (type === "seekers") {
    const {
      name,
      email,
      phoneNumber,
      isEmailVerified,
      isSubscribed,
      fullName,
      profile,
      type,
      vacancies,
      salary,
      employer,
      college,
      price,
      duration,
      amount,
      validity,
      plan,
      user,
      applicationStatus,
      paymentMethod,
    } = data;
    const id = data.id;

    return {
      id,
      name,
      email,
      phoneNumber,
      isEmailVerified,
      isSubscribed,
      type,
      profile,
      vacancies,
      salary,
      employer,
      fullName,
      college,
      price,
      duration,
      amount,
      validity,
      plan,
      user,
      applicationStatus,
      paymentMethod,
    };
  } else if (type === "employers") {
    const {
      name,
      email,
      phoneNumber,
      fullName,
      isSubscribed,
      applicationStatus,
    } = data;
    const id = data.id;

    return {
      id,
      name,
      email,
      phoneNumber,
      fullName,
      isSubscribed,
      applicationStatus,
    };
  }
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const { headCells } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    selectedId,
    handleDeleteSeekerData,
    handleDeleteEmployerData,
    handleDeleteJobData,
    setSelected,
    handleDeleteCourseData,
  } = props;
  const location = useLocation();

  const handleDeleteData = () => {
    if (location.pathname === "/admin/seekers") {
      handleDeleteSeekerData(selectedId);
    } else if (location.pathname === "/admin/employers") {
      handleDeleteEmployerData(selectedId);
    } else if (location.pathname === "/admin/jobs") {
      handleDeleteJobData(selectedId);
    } else {
      handleDeleteCourseData(selectedId);
    }
    setSelected([]);
  };
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {location.pathname === "/admin/jobs" && (
        <Typography sx={{ flex: "1 1 100%" }} color="inherit">
          {/* <Link to="/createJobs">
            <Button variant="text">Create Jobs</Button>
          </Link> */}
        </Typography>
      )}
      {location.pathname === "/admin/courses" && (
        <Typography sx={{ flex: "1 1 100%" }} color="inherit">
          <Link to="/create-course">
            <Button variant="text">Create Courses</Button>
          </Link>
        </Typography>
      )}

      {numSelected > 0 && (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      )}

      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton onClick={handleDeleteData}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  data,
  type,
  headCells,
  handleUpdateSeekerData,
  handleDeleteSeekerData,
  handleUpdateEmployerData,
  handleDeleteEmployerData,
  handleDeleteJobData,
  handleUpdateJobData,
  handleDeleteCourseData,
  handleUpdateCourseData,
  handleUpdateApplicationStatus,
  handleGetPage,
  totalData,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowData, setRowData] = useState(data);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedUserData, setSelectedUserData] = React.useState(null);

  const location = useLocation();
  // console.log("data", data);

  const [open, setOpen] = useState(false);

  const handleOpen = (data) => {
    setOpen(true);
    setRowData(data);
  };

  const handleClose = () => setOpen(false);

  // const handleAcceptStatus = (id, data) => {

  // }

  // const handleRejectStatus = (id, data) => {

  // }

  const rows = data.map((item) =>
    createData(item, item.role ? item.role : "seekers")
  );
  console.log(rows, "=============------------------");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id, data) => {
    const isViewIconClicked = event.target.classList.contains("fa-eye");
    // If the click was on the "view" icon, don't select the row
    if (isViewIconClicked) {
      setSelectedUserData(data);
      setOpen(true);
      return;
    }

    const isEmailLinkClicked = event.target.closest(".useremail");
    if (isEmailLinkClicked) {
      return;
    }

    const isButtonClicked = event.target.closest(".acceptBtn");

    if (isButtonClicked) {
      return;
    }

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    console.log("this is working", newPage);
    handleGetPage(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    handleGetPage(1);
    setPage(0);
  };
  const handleUpdateData = (values) => {
    console.log(values, "inside submit");
    const newValues = {
      name: values.name,
    };

    if (location.pathname === "/admin/seekers") {
      newValues.email = values.email;
      newValues.phoneNumber = values.phoneNumber;
      newValues.profile = values.profile;
      handleUpdateSeekerData(selectedUserData.id, newValues);

      // Handle other specific fields if needed
    } else if (location.pathname === "/admin/employers") {
      // Additional fields specific to /admin/employers
      newValues.email = values.email;
      newValues.phoneNumber = values.phoneNumber;
      newValues.fullName = values.fullName;
      handleUpdateEmployerData(selectedUserData.id, newValues);

      // Handle other specific fields if needed
    } else if (location.pathname === "/admin/jobs") {
      newValues.email = values.email;
      newValues.phoneNumber = values.phoneNumber;
      handleUpdateJobData(selectedUserData.id, values);
    } else {
      handleUpdateCourseData(selectedUserData.id, values);
    }

    handleClose();
  };

  const isSeekersOrEmployers =
    location.pathname === "/admin/seekers" ||
    location.pathname === "/admin/employers";

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  console.log(
    visibleRows,
    "<<<<<<<<<<<<<<<<<<<<<<<<<<<<Vr<<<<<<<<<<<<<<<<<<<<<<<<<<<<<"
  );

  const InitialValues =
    location.pathname === "/admin/seekers"
      ? {
          name: selectedUserData && selectedUserData.name,
          profile: selectedUserData && selectedUserData.profile,
          email: selectedUserData && selectedUserData.email,
          phoneNumber: selectedUserData && selectedUserData.phoneNumber,

          isEmailVerified:
            selectedUserData && selectedUserData.isEmailVerified ? "Yes" : "No",
          isSubscribed:
            selectedUserData && selectedUserData.isSubscribed ? "Yes" : "No",
        }
      : {
          name: selectedUserData && selectedUserData.name,
          fullName: selectedUserData && selectedUserData.fullName,
          email: selectedUserData && selectedUserData.email,
          phoneNumber: selectedUserData && selectedUserData.phoneNumber,

          isEmailVerified:
            selectedUserData && selectedUserData.isEmailVerified ? "Yes" : "No",
          isSubscribed:
            selectedUserData && selectedUserData.isSubscribed ? "Yes" : "No",
        };

  return (
    <Box sx={{ width: "100%" }} className="relative z-10">
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selectedId={selected}
          setSelected={setSelected}
          handleDeleteSeekerData={handleDeleteSeekerData}
          handleDeleteEmployerData={handleDeleteEmployerData}
          handleDeleteJobData={handleDeleteJobData}
          handleDeleteCourseData={handleDeleteCourseData}
        />

        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {visibleRows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              ) : (
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        {location.pathname === "/admin/revenue" ||
                        location.pathname === "/admin/managerequest" ? null : (
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        )}
                      </TableCell>

                      {location.pathname === "/admin/jobs" ? (
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {/*  */}
                          {row.profile}
                        </TableCell>
                      ) : location.pathname === "/admin/revenue" ? (
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {/*  */}
                          {row.user.name}
                        </TableCell>
                      ) : (
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.name}
                        </TableCell>
                      )}
                      {location.pathname === "/admin/jobs" ? (
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.employer}
                        </TableCell>
                      ) : location.pathname === "/admin/revenue" ? (
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.paymentMethod}
                        </TableCell>
                      ) : location.pathname === "/admin/employers" ||
                        location.pathname === "/admin/managerequest" ? (
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.fullName}
                        </TableCell>
                      ) : location.pathname === "/admin/courses" ? (
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.type}
                        </TableCell>
                      ) : (
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          <a
                            href={`mailto: ${row.email}`}
                            className="useremail"
                          >
                            {row.email}
                          </a>
                        </TableCell>
                      )}
                      {location.pathname === "/admin/jobs" ? (
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {/*  */}
                          {row.type}
                        </TableCell>
                      ) : location.pathname === "/admin/revenue" ? (
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {/*  */}
                          {row.plan.plan}
                        </TableCell>
                      ) : location.pathname === "/admin/employers" ? (
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          <a
                            href={`mailto: ${row.email}`}
                            className="useremail"
                          >
                            {row.email}
                          </a>
                        </TableCell>
                      ) : location.pathname === "/admin/courses" ? (
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.college && row.college.name}
                        </TableCell>
                      ) : location.pathname === "/admin/managerequest" ? (
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.email}
                        </TableCell>
                      ) : (
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.phoneNumber}
                        </TableCell>
                      )}
                      {location.pathname === "/admin/jobs" ? (
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {/*  */}
                          {row.vacancies}
                        </TableCell>
                      ) : location.pathname === "/admin/revenue" ? (
                        <TableCell align="left">
                          {row.plan.validity}{" "}
                          {row.plan.plan === "jobPosting"
                            ? "Job Posting"
                            : "resume searches"}
                        </TableCell>
                      ) : location.pathname === "/admin/seekers" ? (
                        <TableCell align="right">{row.profile}</TableCell>
                      ) : location.pathname === "/admin/employers" ? (
                        <TableCell align="right">{row.phoneNumber}</TableCell>
                      ) : location.pathname === "/admin/courses" ? (
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          ${row.price}
                        </TableCell>
                      ) : location.pathname === "/admin/managerequest" ? (
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.applicationStatus}
                        </TableCell>
                      ) : (
                        <TableCell align="right">
                          <div className="flex justify-start">
                            <div
                              className={`${
                                row.isEmailVerified
                                  ? "bg-[#66ffe64d]"
                                  : "bg-[#ff2f4b63]"
                              } text-center ${
                                row.isEmailVerified
                                  ? "text-[#33dfc2] font-bold"
                                  : "text-[#ff2f4b] font-bold"
                              } px-1 rounded-full w-14`}
                            >
                              {row.isEmailVerified ? "Yes" : "No"}
                            </div>
                          </div>
                        </TableCell>
                      )}
                      {location.pathname === "/admin/jobs" ? (
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {/*  */}
                          {row.salary}
                        </TableCell>
                      ) : location.pathname === "/admin/courses" ? (
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.duration}
                        </TableCell>
                      ) : location.pathname === "/admin/revenue" ? (
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          ${row.amount}
                        </TableCell>
                      ) : location.pathname === "/admin/managerequest" ? (
                        <TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            className="acceptBtn"
                          >
                            <button
                              className="text-white bg-green-500 hover:bg-green-600 px-4 py-1 rounded-md mr-4"
                              onClick={() =>
                                handleUpdateApplicationStatus(
                                  row.id,
                                  "approved"
                                )
                              }
                            >
                              Accept
                            </button>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            className="acceptBtn"
                          >
                            <button
                              className="text-white bg-red-500 hover:bg-red-600 px-4 py-1 rounded-md"
                              onClick={() =>
                                handleUpdateApplicationStatus(
                                  row.id,
                                  "rejected"
                                )
                              }
                            >
                              Reject
                            </button>
                          </TableCell>
                        </TableCell>
                      ) : (
                        <TableCell align="right">
                          <div className="flex justify-end">
                            <div
                              className={`${
                                row.isSubscribed
                                  ? "bg-[#66ffe64d]"
                                  : "bg-[#ff2f4b63]"
                              } text-center ${
                                row.isSubscribed
                                  ? "text-[#33dfc2] font-bold"
                                  : "text-[#ff2f4b] font-bold"
                              } px-1 rounded-full w-14`}
                            >
                              {row.isSubscribed ? "Yes" : "No"}
                            </div>
                          </div>
                        </TableCell>
                      )}

                      {location.pathname === "/admin/revenue" ||
                      location.pathname === "/admin/managerequest" ? null : (
                        <TableCell align="right">
                          <span onClick={() => handleOpen(row)}>
                            <i className="fa fa-eye" aria-hidden="true"></i>
                          </span>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })
              )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={totalData}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {/* seeker View Modal */}

        {isSeekersOrEmployers && (
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              rowData={rowData}
            >
              <Box sx={style}>
                <Formik
                  initialValues={InitialValues}
                  validationSchema={
                    location.pathname === "/admin/seekers"
                      ? validationSchema
                      : EmpValidationSchema
                  }
                  onSubmit={handleUpdateData}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div className="flex justify-between mb-8 items-center">
                        <label htmlFor="name">Name:</label>
                        <Field
                          type="text"
                          id="name"
                          name="name"
                          className="rounded-lg shadow-lg border-[#ECEDF2]"
                        />
                      </div>
                      {errors.name && touched.name && (
                        <div className="text-red-500 flex mb-5">
                          {errors.name}
                        </div>
                      )}

                      {location.pathname === "/admin/seekers" ? (
                        <div className="flex justify-between mb-8 items-center">
                          <label htmlFor="profile">Profile:</label>
                          <Field
                            type="text"
                            id="profile"
                            name="profile" // Use "profile" for /admin/seekers
                            className="rounded-lg shadow-lg border-[#ECEDF2]"
                          />
                        </div>
                      ) : (
                        <div className="flex justify-between mb-8 items-center">
                          <label htmlFor="fullName">Full Name:</label>
                          <Field
                            type="text"
                            id="fullName"
                            name="fullName" // Use "fullName" for /admin/employers or other paths
                            className="rounded-lg shadow-lg border-[#ECEDF2]"
                          />
                        </div>
                      )}
                      {/* Common error handling for both cases */}
                      {location.pathname === "/admin/seekers"
                        ? errors.profile &&
                          touched.profile && (
                            <div className="text-red-500 flex mb-5">
                              {errors.profile}
                            </div>
                          )
                        : errors.fullName &&
                          touched.fullName && (
                            <div className="text-red-500 flex mb-5">
                              {errors.fullName}
                            </div>
                          )}

                      <div className="flex justify-between mb-8 items-center">
                        <label htmlFor="email">Email:</label>
                        <Field
                          type="email"
                          id="email"
                          name="email"
                          className="rounded-lg shadow-lg border-[#ECEDF2]"
                        />
                      </div>
                      {errors.email && touched.email && (
                        <div className="text-red-500 flex mb-5">
                          {errors.email}
                        </div>
                      )}

                      <div className="flex justify-between mb-8 items-center">
                        <label htmlFor="phoneNumber">Phone Number:</label>
                        <Field
                          type="text"
                          id="phoneNumber"
                          name="phoneNumber"
                          className="rounded-lg shadow-lg border-[#ECEDF2]"
                        />
                      </div>
                      {errors.phoneNumber && touched.phoneNumber && (
                        <div className="text-red-500 flex mb-5">
                          {errors.phoneNumber}
                        </div>
                      )}

                      <div className="flex justify-between mb-8 items-center">
                        <label htmlFor="isEmailVerified">
                          Is Email Verified:
                        </label>
                        <Field
                          type="text"
                          id="isEmailVerified"
                          name="isEmailVerified"
                          className="rounded-lg shadow-lg border-[#ECEDF2]"
                          disabled
                        />
                      </div>
                      {errors.isEmailVerified && touched.isEmailVerified && (
                        <div className="text-red-500 flex mb-5">
                          {errors.isEmailVerified}
                        </div>
                      )}

                      <div className="flex justify-between mb-8 items-center">
                        <label htmlFor="isSubscribed">Is Subscribed:</label>
                        <Field
                          type="text"
                          id="isSubscribed"
                          name="isSubscribed"
                          className="rounded-lg shadow-lg border-[#ECEDF2]"
                          disabled
                        />
                      </div>
                      {errors.isSubscribed && touched.isSubscribed && (
                        <div className="text-red-500 flex mb-5">
                          {errors.isSubscribed}
                        </div>
                      )}
                      <div className="flex justify-around">
                        <button
                          className="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-16 border-b-4 border-blue-700 hover:border-blue-500 rounded-md"
                          type="submit"
                        >
                          Update
                        </button>
                        <button
                          className="bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-16 border-b-4 border-red-700 hover:border-red-500 rounded-md"
                          onClick={handleClose}
                        >
                          Cancel
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Modal>
          </div>
        )}
        {location.pathname === "/admin/jobs" && (
          <JobModal
            handleUpdateData={handleUpdateData}
            selectedUserData={selectedUserData}
            open={open}
            handleClose={handleClose}
          />
        )}
        {location.pathname === "/admin/courses" && (
          <CourseModal
            handleUpdateData={handleUpdateData}
            selectedUserData={selectedUserData}
            open={open}
            handleClose={handleClose}
          />
        )}
      </Paper>
    </Box>
  );
}
