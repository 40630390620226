import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";

import { Modal } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { college } from "../../redux/reducers/collegeSlice";
import { useSelector } from "react-redux";
import { useGetCollegesQuery } from "../../redux/Apis/collegeApi";
import { useCreateCoursesMutation } from "../../redux/Apis/courseApi";
import { useGetCategoryQuery } from "../../redux/Apis/categoryApi";

const validationSchema = Yup.object().shape({
  type: Yup.string().required("Type is required"),
  name: Yup.string()
    .max(25, "Profile is too long")
    .required("Name is required"),
  college: Yup.string()
    .required("Profile is required")
    .max(25, "Profile is too long"),
  price: Yup.number()
    .typeError("price must be a number")
    .required("price is required")
    .min(1, "must be at least one"),
  duration: Yup.string()
    .required("duration is required")
    .matches(
      /^([0-9]{2}):([0-5][0-9]):([0-5][0-9])$/,
      "Please enter a valid time in the format 'hh:mm:ss'"
    ),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #ECEDF2",
  borderRadius: "5%",
  boxShadow: 24,
  p: 4,
};

const JobModal = ({
  handleUpdateData,
  selectedUserData,
  open,
  handleClose,
}) => {
  // console.log("data", data);

  const InitialValues = {
    name: selectedUserData && selectedUserData.name,

    type: selectedUserData && selectedUserData.type,
    college: selectedUserData && selectedUserData.college,
    price: selectedUserData && selectedUserData.price,
    duration: selectedUserData && selectedUserData.duration,
  };
  console.log(selectedUserData, "<<<<<<<<<<<<<<<<<<<<<<<SELEc");

  const dispatch = useDispatch();

  const [category, setCategory] = useState([]);

  const {
    data: getCategory,
    isLoading,
    error: seekerError,
    isSuccess,
    isError: categoryError,
  } = useGetCategoryQuery();

  const {
    data: getData,
    isLoading: isCollegeLoading,
    error: collegeError,
    isSuccess: collegeFetched,
    isError,
    refetch: getCourseAgain,
  } = useGetCollegesQuery();

  const [
    createCourse,
    { isLoading: isCourseLoading, isSuccess: courseSuccess },
  ] = useCreateCoursesMutation();

  useEffect(() => {
    if (!isCollegeLoading && collegeFetched) {
      dispatch(college(getData.body.results));
    }
  }, [isCollegeLoading, collegeFetched, getData]);

  const handleSubmit = (values) => {
    // const selectedCollegeId = values.college;
    // const selectedCollege = getData.body.results.find(
    //   (college) => college._id === selectedCollegeId
    // );
    const newValues = {
      name: values.name,
      type: values.type,
      price: values.price,
      // college: selectedCollege ? selectedCollege.name : "",
      college: values.college,
      duration: values.duration,
    };
    handleUpdateData(newValues);
  };
  console.log(selectedUserData, "selected user data");
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Formik
            initialValues={InitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="flex justify-between mb-8 items-center">
                  <label htmlFor="name">Name:</label>
                  <Field
                    type="text"
                    id="name"
                    name="name" // Use "profile" for /admin/seekers
                    className="rounded-lg shadow-lg border-[#ECEDF2]"
                  />
                </div>

                {errors.name && touched.name && (
                  <div className="text-red-500 flex mb-5">{errors.name}</div>
                )}

                <div className="flex justify-between mb-8 items-center">
                  <label htmlFor="type">Job Type:</label>
                  <Field
                    type="text"
                    id="type"
                    name="type"
                    className="rounded-lg shadow-lg border-[#ECEDF2]"
                  />
                </div>
                {errors.type && touched.type && (
                  <div className="text-red-500 flex mb-5">{errors.type}</div>
                )}
                <div className="flex justify-between mb-8 items-center">
                  <label htmlFor="price">Salary:</label>
                  <Field
                    id="price"
                    name="price"
                    type="text"
                    className="rounded-lg shadow-lg border-[#ECEDF2]"
                    placeholder="Job price"
                  />
                </div>
                {errors.price && touched.price && (
                  <div className="text-red-500 flex mb-5">{errors.price}</div>
                )}
                <div className="flex justify-between mb-8 items-center">
                  <label htmlFor="price">Duration:</label>
                  <Field
                    id="duration"
                    name="duration"
                    type="text"
                    className="rounded-lg shadow-lg border-[#ECEDF2]"
                    placeholder="12:12:12"
                  />
                </div>
                {errors.duration && touched.duration && (
                  <div className="text-red-500 flex mb-5">
                    {errors.duration}
                  </div>
                )}

                <div className="flex justify-between mb-8 items-center">
                  <label htmlFor="price">College:</label>
                  <Field
                    as="select" // Use "as" prop to render select element
                    id="college"
                    name="college"
                    className="rounded-lg shadow-lg border-[#ECEDF2]"
                    required
                  >
                    <option value="">Select an option</option>
                    {getData &&
                      getData.body &&
                      getData.body.results.map((college, index) => (
                        <option value={college && college._id} key={index}>
                          {college && college.name}
                        </option>
                      ))}
                  </Field>
                </div>
                {errors.college && touched.college && (
                  <div className="text-red-500 flex mb-5">{errors.college}</div>
                )}

                <div className="flex justify-around">
                  <button
                    className="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-16 border-b-4 border-blue-700 hover:border-blue-500 rounded-md"
                    type="submit"
                  >
                    Update
                  </button>
                  <button
                    className="bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-16 border-b-4 border-red-700 hover:border-red-500 rounded-md"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
};

export default JobModal;
