import { splitApi } from "./spilitApi";

export const seekerApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    getAdminData: build.query({
      query: (id) => ({
        // Modify the query function to accept an object
        url: `/users/${id}`,
        method: "get",
      }),
      invalidatesTags: ["Admin"],
    }),
  }),
});

export const { useGetAdminDataQuery } = seekerApi;
