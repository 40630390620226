import React, { useEffect, useState } from "react";
import { createPopper } from "@popperjs/core";
import teamOne from "../../assets/img/team-1-800x800.jpg";
import { useGetAdminDataQuery } from "../../redux/Apis/adminData";
import { Link } from "react-router-dom";

const UserDropdown = () => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();

  const user = localStorage.getItem("user");

  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const {
    data: adminData,
    isLoading: adminLoading,
    isSuccess,
  } = useGetAdminDataQuery(user);

  if (adminLoading && !isSuccess) {
    return <h1>Loading....</h1>;
  }

  return (
    <>
      <a
        className="text-white block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        {adminData ? (
          <div className="items-center flex">
            {adminData.body.name}
            {/* <span className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
              <img
                alt="..."
                className="w-full rounded-full align-middle border-none shadow-lg"
                src={teamOne}
              />
            </span> */}
          </div>
        ) : (
          <Link to="/auth/login">
            <div className="text-lg text-[#f0f8ff]">Login</div>
          </Link>
        )}
      </a>
      {adminData && (
        <div
          ref={popoverDropdownRef}
          className={
            (dropdownPopoverShow ? "block " : "hidden ") +
            "bg-white text-base z-50 float-left py-2 list-none text-start rounded shadow-lg min-w-48"
          }
        >
          <a
            href="#pablo"
            className={
              "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            }
            onClick={(e) => e.preventDefault()}
          >
            <span className="mr-2">Name: </span>
            {adminData ? adminData.body.name : "Login First"}
          </a>
          <a
            href="#pablo"
            className={
              "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            }
            onClick={(e) => e.preventDefault()}
          >
            <span className="mr-2">Email:</span>
            {adminData ? adminData.body.email : ""}
          </a>
          <a
            href="#pablo"
            className={
              "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            }
            onClick={(e) => e.preventDefault()}
          >
            <span className="mr-2">Role: </span>
            {adminData ? adminData.body.role : ""}
          </a>
        </div>
      )}
    </>
  );
};

export default UserDropdown;
