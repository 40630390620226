import { configureStore } from '@reduxjs/toolkit'

import reducers from './rootReducers'
import { authApi } from './Apis/authApi'
import { splitApi } from './Apis/spilitApi'

export const store = configureStore({
  reducer: reducers,
  middleware:(getDefaultMiddleware)=>{
    return getDefaultMiddleware().concat(authApi.middleware).concat(splitApi.middleware)
  }
  
})