import React, { useEffect, useState } from "react";
import { seekerHeaders } from "../../utils/seekerHeader";
import { seeker, totalCount } from "../../redux/reducers/seekerSlice";
import {
  useGetSeekerDataQuery,
  useUpdateSeekerDataMutation,
  useDeleteSeekerDataMutation,
} from "../../redux/Apis/seekerApi";
import EnhancedTable from "../../components/Table/Table";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

const Seekers = () => {
  const seekerData = useSelector((state) => {
    return state.seekerSlice.seeker;
  });
  const dispatch = useDispatch();

  const seekerCount = useSelector((state) => state.seekerSlice.totalCount);

  const [page, setPage] = useState(1);
  const [data, setData] = useState(seekerData);

  const {
    data: getData,
    isLoading,
    error: seekerError,
    isSuccess,
    isError,
    refetch: getSeekerData,
  } = useGetSeekerDataQuery(page);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setData(getData.body.results);
    }
  }, [getData]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(seeker(getData.body.results));
      dispatch(totalCount(getData.body.totalCount));
    }
  }, [isLoading, isSuccess, getData]);

  const handlePageChange = (page) => {
    const resultToSend = [...data];

    dispatch(seeker(data));
    setPage(page + 1);
  };

  const [
    updateSeekerData,
    { isLoading: isUpdating },
  ] = useUpdateSeekerDataMutation();

  const [
    deleteSeekerData,
    { isLoading: isDeleting },
  ] = useDeleteSeekerDataMutation();

  const handleUpdateSeekerData = async (id, updatedData) => {
    try {
      const updatedResponse = await updateSeekerData({ id, data: updatedData }); // Pass as an object
      toast.success("Profile Updated Sucessfully");
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleDeleteSeekerData = async (id) => {
    try {
      const deletedResponse = await deleteSeekerData(id);
      getData();
      console.log("Deleted data:", deletedResponse.data);
      toast.success("Deleted Successfully");
      // Optionally, you can also update the state or refetch data after deletion.
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  return (
    <>
      <div>
        <EnhancedTable
          data={data}
          type="seeker"
          headCells={seekerHeaders}
          totalData={seekerCount}
          handleGetPage={handlePageChange}
          handleUpdateSeekerData={handleUpdateSeekerData}
          handleDeleteSeekerData={handleDeleteSeekerData}
        />
      </div>
    </>
  );
};

export default Seekers;
