export const authInitialState = {
  token: {
    accessToken: "",
    refreshToken: "",
  },
  userData: {},
};

export const jobsInitalState = {
  jobs: [],
  totalCount: 0,
};

export const employerInitialSlice = {
  employer: [],
  totalCount: 0,
};

export const seekerInitialState = {
  seeker: [],
  totalCount: 0,
};

export const courseInitialState = {
  courses: [],
  totalCount: 0,
};

export const collegeInitialState = {
  college: [],
  totalCount: 0,
};
export const revenueInitialState = {
  revenue: [],
  totalCount: 0,
};
export const manageRequestInitialState = {
  manageReq: [],
  totalCount: 0,
};
