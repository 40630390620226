import EnhancedTable from "../../components/Table/Table";
import React, { useEffect, useState } from "react";
import { headCells } from "../../utils/headers";
import { courses, totalCount } from "../../redux/reducers/courseSlice";
import {
  useGetCourseDataQuery,
  useDeleteCourseDataMutation,
  useCreateCoursesMutation,
  useUpdateCourseDataMutation,
} from "../../redux/Apis/courseApi";
import { useDispatch, useSelector } from "react-redux";
import { seekerHeaders } from "../../utils/seekerHeader";
import { courseListing } from "../../utils/courseHeader";
import { toast } from "react-toastify";

const Jobs = () => {
  const courseData = useSelector((state) => state.courseSlice.courses);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [data, setData] = useState(courseData);
  const courseCount = useSelector((state) => state.courseSlice.totalCount);
  const {
    data: getData,
    isLoading,
    error: courseError,
    isSuccess: courseSuccess,
    isError,
    refetch: getCourseAgain,
  } = useGetCourseDataQuery(page);

  useEffect(() => {
    console.log({ data });
    if (!isLoading && !isError) {
      setData([...data, ...getData.body.results]);
    }
  }, [getData]);

  useEffect(() => {
    if (!isLoading && courseSuccess) {
      dispatch(courses(getData.body.results));
      dispatch(totalCount(getData.body.totalCount));
    }
    getCourseAgain();
  }, [isLoading, courseSuccess, getData]);

  const handlePageChange = (page) => {
    const resultToSend = [...data];

    dispatch(courses(data));
    setPage(page + 1);
  };

  const [
    deleteCourseData,
    { isLoading: isDeleting },
  ] = useDeleteCourseDataMutation();

  const handleDeleteCourseData = async (id) => {
    try {
      const deletedResponse = await deleteCourseData(id);
      console.log("Deleted data:", deletedResponse.data);
      getCourseAgain();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const [
    updateCourseData,
    { isLoading: isUpdating },
  ] = useUpdateCourseDataMutation();

  const handleUpdateCourseData = async (id, updatedData) => {
    try {
      const updatedResponse = await updateCourseData({ id, data: updatedData }); // Pass as an object
      toast.success("Profile Updated Sucessfully");
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  if (isLoading && !courseSuccess && courseData.length === 0) {
    return <h1>Loading...</h1>;
  } else {
    return (
      <>
        <EnhancedTable
          data={data}
          totalData={courseCount}
          handleGetPage={handlePageChange}
          type="seeker"
          headCells={courseListing}
          handleDeleteCourseData={handleDeleteCourseData}
          handleUpdateCourseData={handleUpdateCourseData}
        />
      </>
    );
  }
};

export default Jobs;
