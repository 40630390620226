import { createSlice } from "@reduxjs/toolkit";
import { collegeInitialState } from "../reduxState";

export const collegeSlice = createSlice({
  name: "college",
  initialState: collegeInitialState,
  reducers: {
    college: (state, payload) => {
      state.college = payload.payload;
    },
  },
});

export const { college } = collegeSlice.actions;

export default collegeSlice.reducer;

// export const jobSlice = createSlice({
//   name: "jobs",
//   initialState: jobsInitalState,
//   reducers: {
//     jobs: (state, payload) => {
//       console.log({ paulodSeeker: payload });
//       state.jobs = payload.payload;
//     },
//   },
// });

// export const { jobs } = jobSlice.actions;

// export default jobSlice.reducer;
