import {createSlice} from '@reduxjs/toolkit';
import { authInitialState } from '../reduxState';


export const authSlice = createSlice({
    name: 'counter',
    initialState:authInitialState,
    reducers: {
      token: (state,payload) => {
        const {accessToken,refreshToken}=payload
        
        state.token ={...state.token,accessToken,refreshToken}
      },
      userData: (state,payload) => {
        
        state.userData=payload
      }
    },
  })

export const {token, userData }=authSlice.actions

export default authSlice.reducer