// components
import React, { useEffect, useState } from "react";
import { useGetUserDataQuery } from "../../redux/Apis/allUserData";
import CardStats from "../Cards/CardStats";
import { useLocation } from "react-router-dom";

export default function HeaderStats() {
  const location = useLocation();



  const [userData, setUserData] = useState([]);





  const {
    data: getData,
    isLoading,
    error: allError,
    isSuccess,
    isError,
    refetch: getAllData,
  } = useGetUserDataQuery();

  const {
    data: seekerData,
    isLoading: seekerLoading,
    error: seekerError,
    isSuccess: seekerSuccess,
    isError: isSeekerError,
    refetch: getSeekerData,
  } = useGetUserDataQuery("seekers");

  const {
    data: employerData,
    isLoading: employerLoading,
    error: employerError,
    isSuccess: employerSuccess,
    isError: isEmployerError,
    refetch: getEmployerData,
  } = useGetUserDataQuery("employers");



  console.log(employerData, "gjhfghfdfjkhhgjkhkj");

  useEffect(() => {
    console.log(getData, "getData");
    if (getData && getData.body) {
      setUserData(getData.body);

    }
  }, [getData, userData]);

  // useEffect(() => {
  //   if (seekerData && seekerData.body) {
  //     setUserData(getData.body);
  //   }
  // }, [getData, userData]);

  console.log(userData, "userData")

  return (
    <>
      {/* Header */}
      <div className="relative bg-lightBlue-600 md:pt-32 pb-32 pt-12">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Total Users"
                  statTitle={userData.totalCount}
                  statArrow="up"
                  // statPercent="3.48"
                  // statPercentColor="text-emerald-500"
                  // statDescripiron="Since last month"
                  statIconName="far fa-chart-bar"
                  statIconColor="bg-red-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Total Seekers"
                  statTitle={userData.seekersCount}
                  statArrow="down"
                  statPercent="3.48"
                  statPercentColor="text-red-500"
                  statDescripiron="Since last week"
                  statIconName="fas fa-chart-pie"
                  statIconColor="bg-orange-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Total Employers"
                  statTitle={userData.employersCount}
                  statArrow="down"
                  statPercent="1.10"
                  statPercentColor="text-orange-500"
                  statDescripiron="Since yesterday"
                  statIconName="fas fa-users"
                  statIconColor="bg-pink-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Total Jobs"
                  statTitle={userData.jobCount}
                  statArrow="up"
                  statPercent="12"
                  statPercentColor="text-emerald-500"
                  statDescripiron="Since last month"
                  statIconName="fas fa-percent"
                  statIconColor="bg-lightBlue-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
