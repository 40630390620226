import { splitApi } from "./spilitApi";

export const employerApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    getEmployerData: build.query({
      query: (data) => ({
        url: `/users?role=employers&page=${data}`,
        method: "GET",
      }),
      providesTags: ["Employers"],
    }),
    updateEmployerData: build.mutation({
      query: ({ id, data }) => ({
        url: `/users/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Employers"],
    }),
    deleteEmployerData: build.mutation({
      query: (id) => ({
        url: `/users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Employers"],
    }),
  }),
});

export const {
  useGetEmployerDataQuery,
  useUpdateEmployerDataMutation,
  useDeleteEmployerDataMutation,
} = employerApi;
