export const courseListing = [
  {

    id: "profile",
    numeric: false,
    disablePadding: true,
    label: "Profile",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "college",
    numeric: false,
    disablePadding: false,
    label: "College",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: true,
    label: "Price",
  },
  {
    id: "duration",
    numeric: false,
    disablePadding: true,
    label: "Duration",
  },
  {
    id: "view",
    numeric: true,
    disablePadding: false,
    label: "View",
  },
];
