import React, { useEffect, useState } from "react";
import { manageReqHeader } from "../../utils/manageReqHeader";
import EnhancedTable from "../../components/Table/Table";
import { manageReq } from "../../redux/reducers/manageRequestSlice";
import {
  useGetManageReqDataQuery,
  useUpdateApplicationStatusMutation,
} from "../../redux/Apis/manageRequest";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { totalCount } from "../../redux/reducers/manageRequestSlice";

const ManageRequest = () => {
  const manageRequest = useSelector((state) => {
    return state.manageRequestSlice.manageReq;
  });

  const [page, setPage] = useState(1);
  const [data, setData] = useState(manageRequest);
  console.log({ page });
  const requestCount = useSelector(
    (state) => state.manageRequestSlice.totalCount
  );

  const {
    data: getData,
    isLoading,
    error: seekerError,
    isSuccess,
    isError,
    refetch: getRequests,
  } = useGetManageReqDataQuery(page);

  useEffect(() => {
    console.log({ data });
    if (!isLoading && isSuccess) {
      setData([...data, ...getData.body.results]);
    }
  }, [getData]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(manageReq(getData.body.results));
      dispatch(totalCount(getData.body.totalCount));
    }
  }, [isLoading, isSuccess, getData]);

  const handlePageChange = (page) => {
    const resultToSend = [...data];

    dispatch(manageReq(data));
    setPage(page + 1);
  };

  const [
    updateApplicationStatus,
    { isLoading: applicationLoading, isSuccess: applicationSuccess },
  ] = useUpdateApplicationStatusMutation();

  const handleUpdateApplicationStatus = async (id, updatedStatus) => {
    try {
      const updatedData = { status: updatedStatus };

      const updatedApplication = await updateApplicationStatus({
        id,
        data: updatedData,
      });

      if (updatedApplication) {
        getRequests(); // Refresh data after successful update
      }
      if (applicationSuccess) {
        toast.success("Application" + updatedData + " successfully");
      }
    } catch (error) {
      console.error("Error updating application status:", error);
    }
  };

  if (isLoading && !isSuccess && manageReq.length === 0) {
    return <h1>Loading...</h1>;
  } else {
    return (
      <>
        <div>
          <EnhancedTable
            data={data}
            handleGetPage={handlePageChange}
            totalData={requestCount}
            type="employer"
            headCells={manageReqHeader}
            handleUpdateApplicationStatus={handleUpdateApplicationStatus}
          />
        </div>
      </>
    );
  }
};

export default ManageRequest;
