import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./reducers/authSlice";
import { authApi } from "./Apis/authApi";
import { seekerApi } from "./Apis/seekerApi";
import { splitApi } from "./Apis/spilitApi";
import jobSlice from "./reducers/jobSlice";
import courseSlice from "./reducers/courseSlice";
import collegeSlice from "./reducers/collegeSlice";
import revenueSlice from "./reducers/revenueSlice";
import manageRequestSlice from "./reducers/manageRequestSlice";
import seekerSlice from "./reducers/seekerSlice";
import employerSlice from "./reducers/employerSlice";

const reducers = combineReducers({
  authSlice: authSlice,
  jobSlice: jobSlice,
  courseSlice: courseSlice,
  collegeSlice: collegeSlice,
  revenueSlice: revenueSlice,
  manageRequestSlice: manageRequestSlice,
  seekerSlice: seekerSlice,
  employerSlice: employerSlice,
  [authApi.reducerPath]: authApi.reducer,
  [seekerApi.reducerPath]: seekerApi.reducer,
  [splitApi.reducerPath]: splitApi.reducer,
});

export default reducers;
