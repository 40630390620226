import { splitApi } from "./spilitApi";

export const revenueApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    getRevenueData: build.query({
      query: (data) => ({
        url: `/payment?page=${data}`,
        method: "GET",
      }),
      providesTags: ["Seekers"],
    }),
  }),
});

export const { useGetRevenueDataQuery } = revenueApi;
