import { createSlice } from "@reduxjs/toolkit";
import { revenueInitialState } from "../reduxState";

export const revenueSlice = createSlice({
  name: "revenue",
  initialState: revenueInitialState,
  reducers: {
    revenue: (state, payload) => {
      console.log({ coursePayload: payload });
      state.revenue = payload.payload;
    },
    totalCount: (state, payload) => {
      state.totalCount = payload.payload;
    },
  },
});

export const { revenue, totalCount } = revenueSlice.actions;

export default revenueSlice.reducer;
