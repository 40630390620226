import { splitApi } from "./spilitApi";

export const seekerApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    getSeekerData: build.query({
      query: (data) => ({
        url: `/users?role=seekers&page=${data}`,
        method: "GET",
      }),
      providesTags: ["Seekers"],
    }),
    updateSeekerData: build.mutation({
      query: ({ id, data }) => ({
        // Modify the query function to accept an object
        url: `/users/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Seekers"],
    }),
    deleteSeekerData: build.mutation({
      query: (id) => ({
        url: `/users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Seekers"],
    }),
  }),
});

export const {
  useGetSeekerDataQuery,
  useUpdateSeekerDataMutation,
  useDeleteSeekerDataMutation,
} = seekerApi;
