export const revenueHeader = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "payment",
    numeric: false,
    disablePadding: true,
    label: "Payment method",
  },
  {
    id: "plan",
    numeric: false,
    disablePadding: false,
    label: "Plan",
  },
  {
    id: "validity",
    numeric: false,
    disablePadding: false,
    label: "Validity",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: true,
    label: "Amount",
  },
];
