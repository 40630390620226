import EnhancedTable from "../../components/Table/Table";
import { empHeadCells } from "../../utils/employerHeader.js";
import {
  useGetEmployerDataQuery,
  useUpdateEmployerDataMutation,
  useDeleteEmployerDataMutation,
} from "../../redux/Apis/employerApi";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { employer, totalCount } from "../../redux/reducers/employerSlice";

const Employers = () => {
  const employerData = useSelector((state) => state.employerSlice.employer);

  const [page, setPage] = useState(1);
  const [data, setData] = useState(employerData);

  const employerCount = useSelector((state) => state.employerSlice.totalCount);

  const {
    data: getData,
    isLoading,
    error: seekerError,
    isSuccess,
    isError,
  } = useGetEmployerDataQuery(page);

  useEffect(() => {
    console.log({ data });
    if (!isLoading && isSuccess) {
      setData(getData.body.results);
    }
  }, [getData]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(employer(getData.body.results));
      dispatch(totalCount(getData.body.totalCount));
    }
  }, [isLoading, isSuccess, getData]);

  const handlePageChange = (page) => {
    const resultToSend = [...data];

    dispatch(employer(data));
    setPage(page + 1);
  };

  const [
    updateEmployerData,
    { isLoading: isUpdating },
  ] = useUpdateEmployerDataMutation();

  const [
    deleteEmployerData,
    { isLoading: isDeleting },
  ] = useDeleteEmployerDataMutation();

  const handleUpdateEmployerData = async (id, updatedData) => {
    try {
      const updatedResponse = await updateEmployerData({
        id,
        data: updatedData,
      }); // Pass as an object
      toast.success("Profile Updated Sucessfully");
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleDeleteEmployerData = async (id) => {
    try {
      const deletedResponse = await deleteEmployerData(id);
      console.log("Deleted data:", deletedResponse.data);
      toast.success("Deleted Successfully");
      // Optionally, you can also update the state or refetch data after deletion.
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  return (
    <>
      <div>
        <EnhancedTable
          data={data}
          type="employers"
          handleGetPage={handlePageChange}
          totalData={employerCount}
          headCells={empHeadCells}
          handleDeleteEmployerData={handleDeleteEmployerData}
          handleUpdateEmployerData={handleUpdateEmployerData}
        />
      </div>
    </>
  );
};

export default Employers;
