import Sidebar from "../Sidebar/Sidebar";
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useCreateJobsMutation } from "../../redux/Apis/jobsSeeker";
import { useGetCategoryQuery } from "../../redux/Apis/categoryApi";
import { useSelector } from "react-redux";

const validationSchema = Yup.object().shape({
  profile: Yup.string()
    .required("Job Title is required")
    .max(50, "Profile is too long"),
  employer: Yup.string()
    .required("Job Title is required")
    .max(50, "Profile is too long"),

  type: Yup.string().required("Job Preference is required"),

  description: Yup.string().required("Description is required"),
  address: Yup.string().required("Address is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  salary: Yup.number()
    .typeError("salary must be a number")
    .required("salary is required")
    .min(1, "must be atleast one"),
  category: Yup.string().required("Category is required"),
  websiteUrl: Yup.string()
    .url("Invalid URL format")
    .required("Website URL is required"),
  deadlineDate: Yup.date().required("Deadline date is required"),
  vacancies: Yup.number()
    .typeError("Vacancies must be a number")
    .required("Vacancies is required")
    .min(1, "must be atleast one")
    .max(100, "vacancies cannot exceeds 100"),
  latitude: Yup.number()
    .typeError("Latitude must be a number")
    .required("Latitude is required")
    .min(-90, "Latitude must be at least -90")
    .max(90, "Latitude cannot exceed 90"),
  longitude: Yup.number()
    .typeError("Longitude must be a number")
    .required("Longitude is required")
    .min(-180, "Longitude must be at least -180")
    .max(180, "Longitude cannot exceed 180"),
});

const JobForm = () => {
  const selector = useSelector((state) => state.authSlice.token);
  const [category, setCategory] = useState([]);

  const {
    data: getCategory,
    isLoading: categoryLoading,
    error: seekerError,
    isSuccess,
    isError: categoryError,
  } = useGetCategoryQuery();

  useEffect(() => {
    if (
      isSuccess &&
      getCategory &&
      getCategory.body &&
      getCategory.body.results
    ) {
      setCategory(getCategory.body.results);
    }
  }, [categoryLoading, getCategory]);

  const initialValues = {
    profile: "",
    type: "",
    salary: "",
    category: "",
    description: "",
    vacancies: "",
    address: "",
    state: "",
    city: "",
    websiteUrl: "",
    deadlineDate: "",
    employer: "",
  };

  const [createJobs, { isLoading, error }] = useCreateJobsMutation();

  const handleSubmit = async (values) => {
    console.log(values, "<<<<<<<<<<<<<<<<<,values");
    try {
      const response = await createJobs(values);

      console.log("Job created successfully:", response.data);
    } catch (err) {
      console.error("Error creating job:", err);
    }
  };

  console.log(
    category,
    "<<<<<<<<<<<<<<<<<<<<<<<<<<category<<<<<<<<<<<<<<<<<<<"
  );

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64">
        <div>
          <div className="flex w-2/3 align-center mt-6 mb-10 mx-auto">
            <h1 className="sector-heading text-black text-[20px] md:text-[36px] font-semibold">
              Create Job
            </h1>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="w-2/3 mx-auto mb-16 ">
                <div className="flex flex-wrap mb-6">
                  <div className="relative w-full md:w-1/2 px-3 my-6 md:mb-0">
                    <label
                      className="block tracking-wide text-[#1D1D1D] text-[20px] font-medium mb-2"
                      htmlFor="profile"
                    >
                      Job Title
                    </label>
                    <Field
                      id="profile"
                      name="profile"
                      type="text"
                      className="bg-[#F6F8FF] pt-3 py-6 pr-10 md:py-6 border-none text-[#1D1D1D] font-1xl sm:text-sm rounded-lg block w-full p-2.5"
                      placeholder="React js Developer"
                    />
                    <ErrorMessage
                      name="profile"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="relative w-full md:w-1/2 px-3 my-6 md:mb-0">
                    <label
                      className="block tracking-wide text-[#1D1D1D] text-[20px] font-medium mb-2"
                      htmlFor="employer"
                    >
                      Employer
                    </label>
                    <Field
                      id="employer"
                      name="employer"
                      type="text"
                      className="bg-[#F6F8FF] pt-3 py-6 pr-10 md:py-6 border-none text-[#1D1D1D] font-1xl sm:text-sm rounded-lg block w-full p-2.5"
                      placeholder="Digimantra"
                    />
                    <ErrorMessage
                      name="employer"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="relative w-full md:w-1/2 px-3 my-6 md:mb-0">
                    <label
                      className="block tracking-wide text-[#1D1D1D] text-[20px] font-medium mb-2"
                      htmlFor="jobType"
                      name="type"
                    >
                      Job Type
                    </label>

                    <Field
                      as="select"
                      name="type"
                      id="job-type"
                      className="appearance-none placeholder-[#25323F] block w-full bg-[#F6F8FF] border-none text-[#1D1D1D] rounded py-6 md:py-6 px-4 mb-3 leading-tight focus:outline-none pl-10"
                      placeholder="Select"
                    >
                      <option value="">Select Job Type</option>
                      <option value="permanent">permanent</option>
                      <option value="contract">contract</option>
                      <option value="part_time">Part Time</option>
                    </Field>
                    <ErrorMessage
                      name="type"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  {/* <div className="relative w-full md:w-1/2 px-3 my-6 md:mb-0">
                    <label
                      className="block tracking-wide text-[#1D1D1D] text-[20px] font-medium mb-2"
                      htmlFor="type"
                    >
                      Job Prefrence
                    </label>
                    <Field
                      id="type"
                      name="type"
                      type="text"
                      className="bg-[#F6F8FF] pt-3 py-6 pr-10 md:py-6 border-none text-[#1D1D1D] font-1xl sm:text-sm rounded-lg block w-full p-2.5"
                      placeholder="Part Time"
                    />
                    <ErrorMessage
                      name="type"
                      component="div"
                      className="text-red-500"
                    />
                  </div> */}

                  <div className="relative w-full md:w-1/2 px-3 my-6 md:mb-0">
                    <label
                      className="block tracking-wide text-[#1D1D1D] text-[20px] font-medium mb-2"
                      htmlFor="salary"
                    >
                      Salary
                    </label>
                    <Field
                      id="salary"
                      name="salary"
                      type="text"
                      className="bg-[#F6F8FF] pt-3 py-6 pr-10 md:py-6 border-none text-[#1D1D1D] font-1xl sm:text-sm rounded-lg block w-full p-2.5"
                      placeholder="Job salary"
                    />
                    <ErrorMessage
                      name="salary"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="relative w-full md:w-1/2 px-3 my-6 md:mb-0">
                    <label
                      className="block tracking-wide text-[#1D1D1D] text-[20px] font-medium mb-2"
                      htmlFor="category"
                    >
                      Category
                    </label>
                    <Field
                      as="select" // Use "as" prop to render select element
                      id="category"
                      name="category"
                      className="bg-[#F6F8FF] pt-3 py-6 pr-10 md:py-6 border-none text-[#1D1D1D] font-1xl sm:text-sm rounded-lg block w-full p-2.5"
                    >
                      <option value="">Select an option</option>
                      {category &&
                        category.map((item, index) => (
                          <option
                            value={item && item._id}
                            key={item && item.id}
                          >
                            {item && item.name}{" "}
                            {/* Display the category name */}
                          </option>
                        ))}
                    </Field>
                    <ErrorMessage
                      name="category"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="relative w-full md:w-1/2 px-3 my-6 md:mb-0">
                    <label
                      className="block tracking-wide text-[#1D1D1D] text-[20px] font-medium mb-2"
                      htmlFor="description"
                    >
                      Description
                    </label>
                    <Field
                      as="textarea"
                      name="description"
                      className="appearance-none block w-full bg-[#F6F8FF] border-none text-gray-700 border-gray-200 rounded py-3 px-4 leading-tight"
                      id="description"
                      placeholder="description"
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="relative w-full md:w-1/2 px-3 my-6 md:mb-0">
                    <label
                      className="block tracking-wide text-[#1D1D1D] text-[20px] font-medium mb-2"
                      htmlFor="vacancies"
                    >
                      Vacancies
                    </label>
                    <Field
                      id="vacancies"
                      name="vacancies"
                      type="text"
                      className="bg-[#F6F8FF] pt-3 py-6 pr-10 md:py-6 border-none text-[#1D1D1D] font-1xl sm:text-sm rounded-lg block w-full p-2.5"
                      placeholder="Part Time"
                    />
                    <ErrorMessage
                      name="vacancies"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="relative w-full px-3 my-6 md:mb-0">
                    <label
                      className="block tracking-wide text-[#1D1D1D] text-[20px] font-medium mb-2"
                      htmlFor="address"
                    >
                      Address
                    </label>
                    <Field
                      id="address"
                      name="address"
                      type="text"
                      className="bg-[#F6F8FF] pt-3 py-6 pr-10 md:py-6 border-none text-[#1D1D1D] font-1xl sm:text-sm rounded-lg block w-full p-2.5"
                      placeholder="phase 5 mohali sector 59 punjab"
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="relative w-full md:w-1/2 px-3 my-6 md:mb-0">
                    <label
                      className="block tracking-wide text-[#1D1D1D] text-[20px] font-medium mb-2"
                      htmlFor="state"
                    >
                      State
                    </label>
                    <Field
                      id="state"
                      name="state"
                      type="text"
                      className="bg-[#F6F8FF] pt-3 py-6 pr-10 md:py-6 border-none text-[#1D1D1D] font-1xl sm:text-sm rounded-lg block w-full p-2.5"
                      placeholder="Chandigarh"
                    />
                    <ErrorMessage
                      name="state"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="relative w-full md:w-1/2 px-3 my-6 md:mb-0">
                    <label
                      className="block tracking-wide text-[#1D1D1D] text-[20px] font-medium mb-2"
                      htmlFor="city"
                    >
                      City
                    </label>
                    <Field
                      id="city"
                      name="city"
                      type="text"
                      className="bg-[#F6F8FF] pt-3 py-6 pr-10 md:py-6 border-none text-[#1D1D1D] font-1xl sm:text-sm rounded-lg block w-full p-2.5"
                      placeholder="Mohali"
                    />
                    <ErrorMessage
                      name="city"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="relative w-full md:w-1/2 px-3 my-6 md:mb-0">
                    <label
                      className="block tracking-wide text-[#1D1D1D] text-[20px] font-medium mb-2"
                      htmlFor="websiteUrl"
                    >
                      Website url
                    </label>
                    <Field
                      id="websiteUrl"
                      name="websiteUrl"
                      type="websiteUrl"
                      className="bg-[#F6F8FF] pt-3 py-6 pr-10 md:py-6 border-none text-[#1D1D1D] font-1xl sm:text-sm rounded-lg block w-full p-2.5"
                      placeholder="https://www.w3schools.com/"
                    />
                    <ErrorMessage
                      name="websiteUrl"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="relative w-full md:w-1/2 px-3 my-6 md:mb-0">
                    <label
                      className="block tracking-wide text-[#1D1D1D] text-[20px] font-medium mb-2"
                      htmlFor="deadlineDate"
                    >
                      Deadline date
                    </label>
                    <Field
                      id="deadlineDate"
                      name="deadlineDate"
                      type="date"
                      className="bg-[#F6F8FF] pt-3 py-6 pr-10 md:py-6 border-none text-[#1D1D1D] font-1xl sm:text-sm rounded-lg block w-full p-2.5"
                      placeholder="12/12/2023"
                    />
                    <ErrorMessage
                      name="deadlineDate"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="relative w-full md:w-1/2 px-3 my-6 md:mb-0">
                    <label
                      className="block tracking-wide text-[#1D1D1D] text-[20px] font-medium mb-2"
                      htmlFor="latitude"
                    >
                      Latitude
                    </label>
                    <Field
                      id="latitude"
                      name="latitude"
                      type="text"
                      className="bg-[#F6F8FF] pt-3 py-6 pr-10 md:py-6 border-none text-[#1D1D1D] font-1xl sm:text-sm rounded-lg block w-full p-2.5"
                      placeholder="44.968046"
                    />
                    <ErrorMessage
                      name="latitude"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="relative w-full md:w-1/2 px-3 my-6 md:mb-0">
                    <label
                      className="block tracking-wide text-[#1D1D1D] text-[20px] font-medium mb-2"
                      htmlFor="longitude"
                    >
                      Longitude
                    </label>
                    <Field
                      id="longitude"
                      name="longitude"
                      type="text"
                      className="bg-[#F6F8FF] pt-3 py-6 pr-10 md:py-6 border-none text-[#1D1D1D] font-1xl sm:text-sm rounded-lg block w-full p-2.5"
                      placeholder="89.132008"
                    />
                    <ErrorMessage
                      name="longitude"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full justify-center enquire-btn inline-flex items-center h-16 font-bold mt-5 bg-[#bdceee] text-black rounded-xl text-xl py-2 pl-[3.5rem] pr-[3.5rem]"
                >
                  Create Job
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default JobForm;
