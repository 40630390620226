import React from "react";
import Box from "@mui/material/Box";

import { Modal } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  employer: Yup.string()
    .required("Employer name is required")
    .max(45, "Name is too long"),
  profile: Yup.string()
    .required("Profile is required")
    .max(60, "Profile is too long"),
  type: Yup.string().required("Type is required"),
  vacancies: Yup.number()
    .typeError("Vacancies must be a number")
    .required("Vacancies is required")
    .min(1, "must be atleast one")
    .max(100, "vacancies cannot exceeds 100"),
  salary: Yup.number()
    .typeError("salary must be a number")
    .required("salary is required")
    .min(1, "must be atleast one"),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #ECEDF2",
  borderRadius: "5%",
  boxShadow: 24,
  p: 4,
};

const JobModal = ({
  handleUpdateData,
  selectedUserData,
  open,
  handleClose,
}) => {
  // console.log("data", data);

  const InitialValues = {
    employer: selectedUserData && selectedUserData.employer,
    profile: selectedUserData && selectedUserData.profile,
    type: selectedUserData && selectedUserData.type,
    vacancies: selectedUserData && selectedUserData.vacancies,
    salary: selectedUserData && selectedUserData.salary,
  };
  console.log(selectedUserData, "<<<<<<<<<<<<<<<<<<<<<<<SELEc");

  const handleSubmit = (values) => {
    console.log(values);
    const newValues = {
      employer: values.employer,
      profile: values.profile,
      type: values.type,
      salary: values.salary,
      vacancies: values.vacancies,
    };
    handleUpdateData(newValues);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Formik
            initialValues={InitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="flex justify-between mb-8 items-center">
                  <label htmlFor="profile">Profile:</label>
                  <Field
                    type="text"
                    id="profile"
                    name="profile" // Use "profile" for /admin/seekers
                    className="rounded-lg shadow-lg border-[#ECEDF2]"
                  />
                </div>

                {/* Common error handling for both cases */}

                {errors.profile && touched.profile && (
                  <div className="text-red-500 flex mb-5">{errors.profile}</div>
                )}

                <div className="flex justify-between mb-8 items-center">
                  <label htmlFor="employer">Employer Name:</label>
                  <Field
                    type="text"
                    id="employer"
                    name="employer"
                    className="rounded-lg shadow-lg border-[#ECEDF2]"
                  />
                </div>
                {errors.employer && touched.employer && (
                  <div className="text-red-500 flex mb-5">
                    {errors.employer}
                  </div>
                )}

                <div className="flex justify-between mb-8 items-center">
                  <label htmlFor="type">Job Type:</label>
                  <Field
                    type="text"
                    id="type"
                    name="type"
                    className="rounded-lg shadow-lg border-[#ECEDF2]"
                  />
                </div>
                {errors.type && touched.type && (
                  <div className="text-red-500 flex mb-5">{errors.type}</div>
                )}

                <div className="flex justify-between mb-8 items-center">
                  <label htmlFor="vacancies">Vacancies:</label>
                  <Field
                    type="text"
                    id="vacancies"
                    name="vacancies"
                    className="rounded-lg shadow-lg border-[#ECEDF2]"
                  />
                </div>
                {errors.vacancies && touched.vacancies && (
                  <div className="text-red-500 flex mb-5">
                    {errors.vacancies}
                  </div>
                )}

                <div className="flex justify-between mb-8 items-center">
                  <label htmlFor="salary">Salary:</label>
                  <Field
                    id="salary"
                    name="salary"
                    type="text"
                    className="rounded-lg shadow-lg border-[#ECEDF2]"
                    placeholder="Job salary"
                  />
                </div>
                {errors.salary && touched.salary && (
                  <div className="text-red-500 flex mb-5">{errors.salary}</div>
                )}

                <div className="flex justify-around">
                  <button
                    className="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-16 border-b-4 border-blue-700 hover:border-blue-500 rounded-md"
                    type="submit"
                  >
                    Update
                  </button>
                  <button
                    className="bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-16 border-b-4 border-red-700 hover:border-red-500 rounded-md"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
};

export default JobModal;
