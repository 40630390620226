import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

// components
import AdminNavbar from "../components/Navbars/AdminNavbar";
import Sidebar from "../components/Sidebar/Sidebar";
import HeaderStats from "../components/Headers/HeaderStats";
import FooterAdmin from "../components/Footers/FooterAdmin";
import { useSelector } from "react-redux";
import { useGetEmployerDataQuery } from "../redux/Apis/employerApi";
// views

export default function Admin() {
  // const selector = useSelector((state) => state.authSlice.token);

  // const {
  //   data: getData,
  //   isLoading,
  //   error: seekerError,
  //   isSuccess,
  //   isError,
  // } = useGetSeekerDataQuery();
  // useEffect(() => {
  //   if (!isLoading) {
  //     console.log({ getData });
  //   }
  // }, [isLoading]);
  // console.log(selector);

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24 ">
          {localStorage.getItem("accessToken") ? (
            <Outlet />
          ) : (
            <Navigate to="/auth/login" />
          )}
          {/* <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />
            <Route path="/admin/maps" exact component={Maps} />
            <Route path="/admin/settings" exact component={Settings} />
            <Route path="/admin/tables" exact component={Tables} />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch> */}
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
