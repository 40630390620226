import { createSlice } from "@reduxjs/toolkit";
import { jobsInitalState } from "../reduxState";

export const jobSlice = createSlice({
  name: "jobs",
  initialState: jobsInitalState,
  reducers: {
    jobs: (state, payload) => {
      debugger;
      console.log({ paulodSeeker: payload });
      state.jobs = payload.payload;
    },
    totalCount: (state, payload) => {
      state.totalCount = payload.payload;
    },
  },
});

export const { jobs, totalCount } = jobSlice.actions;

export default jobSlice.reducer;
