import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { Link } from "react-router-dom";
import { usePostDataMutation } from "../../redux/Apis/authApi";
import { useDispatch } from "react-redux";
import { token, userData } from "../../redux/reducers/authSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export default function Login() {
  const navigate = useNavigate();
  const [
    loginAdmin,
    { isLoading, error: loginError, data: loginData, isSuccess: dataArrived },
  ] = usePostDataMutation();
  const dispatch = useDispatch();
  const handleSubmit = (values, { setSubmitting }) => {
    // console.log({ values });
    if (
      values !== undefined &&
      values.hasOwnProperty("email") &&
      values.hasOwnProperty("password")
    ) {
      const { email, password } = values;
      const data = {
        email: email,
        password: password,
      };
      loginAdmin(data);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (
      !isLoading &&
      loginData !== undefined &&
      loginData.hasOwnProperty("body") &&
      loginData.body.user.role === "super_admin"
    ) {
      dispatch(token(loginData.body.tokens));
      dispatch(userData(loginData.body.user));
      localStorage.setItem("accessToken", loginData.body.tokens.accessToken);
      localStorage.setItem("user", loginData.body.user.id);
      toast.success("Login successfull");
      navigate("/");
    } else {
      if (loginError) {
        toast.error("Login failed. Please check your email and password.");
      }
    }
  }, [isLoading]);

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form className="flex-auto px-4 lg:px-10 py-10 pt-3">

                    <div className="flex justify-center font-bold text-xl">
                      OSR JOBS ADMIN
                    </div>


                    {/* Your form inputs */}
                    <div className="relative w-full mb-10">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mt-8 mb-4"
                        htmlFor="grid-password"
                      >
                        Email
                      </label>
                      <Field
                        type="email"
                        name="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>

                    <div className="relative w-full mb-10">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-4"
                        htmlFor="grid-password"
                      >
                        Password
                      </label>
                      <Field
                        type="password"
                        name="password"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Password"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>

                    <div className="text-center mt-6">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      >
                        {isSubmitting ? "signing" : "sign in"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <a
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  className="text-blueGray-200"
                >
                  <small>Forgot password?</small>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
