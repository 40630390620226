import { createSlice } from "@reduxjs/toolkit";
import { seekerInitialState } from "../reduxState";

export const seekerSlice = createSlice({
  name: "seeker",
  initialState: seekerInitialState,
  reducers: {
    seeker: (state, payload) => {
      console.log({ paulodSeeker: payload });
      state.seeker = payload.payload;
    },
    totalCount: (state, payload) => {
      state.totalCount = payload.payload;
    },
  },
});

export const { seeker, totalCount } = seekerSlice.actions;

export default seekerSlice.reducer;
