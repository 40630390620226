import React, { useEffect, useState } from "react";
import { revenueHeader } from "../../utils/revenueHeader";
import { useGetRevenueDataQuery } from "../../redux/Apis/revenueApi";
import EnhancedTable from "../../components/Table/Table";
import { revenue, totalCount } from "../../redux/reducers/revenueSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

const Revenue = () => {
  const revenueData = useSelector((state) => state.revenueSlice.revenue);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [data, setData] = useState(revenueData);
  const revenueCount = useSelector((state) => state.revenueSlice.totalCount);

  const {
    data: getData,
    isLoading,
    error: seekerError,
    isSuccess: revenueSuccess,
    isError,
  } = useGetRevenueDataQuery(page);

  useEffect(() => {
    console.log({ data });
    if (!isLoading && revenueSuccess) {
      setData([...data, ...getData.body.results]);
    }
  }, [getData]);

  useEffect(() => {
    if (!isLoading && revenueSuccess) {
      dispatch(revenue(getData.body.results));
      dispatch(totalCount(getData.body.totalCount));
    }
  }, [isLoading, revenueSuccess, getData]);

  const handlePageChange = (page) => {
    const resultToSend = [...data];

    dispatch(revenue(data));
    setPage(page + 1);
  };

  console.log(revenueData, "payment");
  return (
    <>
      <div>
        <EnhancedTable
          data={data}
          handleGetPage={handlePageChange}
          totalData={revenueCount}
          type="seeker"
          headCells={revenueHeader}
        />
      </div>
    </>
  );
};

export default Revenue;
