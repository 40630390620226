import { createSlice } from "@reduxjs/toolkit";
import { courseInitialState } from "../reduxState";

export const courseSlice = createSlice({
  name: "courses",
  initialState: courseInitialState,
  reducers: {
    courses: (state, payload) => {
      console.log({ coursePayload: payload });
      state.courses = payload.payload;
    },
    totalCount: (state, payload) => {
      state.totalCount = payload.payload;
    },
  },
});

export const { courses, totalCount } = courseSlice.actions;

export default courseSlice.reducer;
